.column {
  display: flex;
  width: 100%;
  max-width: 435px;

  @media (max-width: 1000px) {
    display: none;
  }
}

.additional {
  padding: 10px 0 0;
  width: 100%;
  max-width: 435px;
}
