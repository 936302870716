.task {
  display: flex;
  flex-direction: column;
  width: 100%;
  // gap: 25px;
  padding: 20px 0;
  // max-width: 355px;
  // height: 100%;

  @media screen and (min-width: 1000px) {
    background: var(--gray);
  }

  overflow-y: scroll;
  height: 100%;
  max-height: calc(100vh - 134px);

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  @media (max-width: 1000px) {
    overflow: visible;
    max-height: 100%;
  }
}

.formBackground {
  position: absolute;
  z-index: -1;
  background: var(--back-gradient);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    top: -153px;
    right: -94px;
    animation: animate 15s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    left: -84px;
    right: 0;
    bottom: -50px;
    animation: animate 30s linear infinite;
  }
}

@keyframes animate {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(100px);
  }
}

.label {
  display: flex;
  flex-direction: row;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid rgba(215, 221, 240, 0.5);
  margin: 0 20px;

  @media screen and (min-width: 1000px) {
    margin: 0 20px;
  }
}

.selectContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.orgName {
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 13px */
  letter-spacing: 0.13px;
}

.dateItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  margin: 13px 0 8px;
}

.dateLogo {
  display: block;
  width: 16px;
  height: 16px;
  background: url(../../../assets/task/time_white.svg) center no-repeat;
}

.dateText {
  color: #ffffff;
  font-size: 14px;
  font-family: Roboto, sans-serif;
  line-height: 12px;
  margin: 0px 0 0;
}

.additional {
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
  margin: -23px 0;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  padding: 55px 36px;

  @media screen and (min-width: 1000px) {
    background-color: transparent;
    border-radius: 0;
    margin: 0 20px;
    padding: 0;
  }
}

.subtasksLabel {
  margin: 12px 0 0;
  border: none;
  border-bottom: 1px solid #bfc8e8;
  min-height: 100px;
  position: relative;
}

.text {
  color: #bfc8e8;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  margin: 0 0 10px;

  @media screen and (min-width: 1000px) {
    color: #fff;
  }
}

.subtasksItems {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subtaskItem {
  display: flex;
  align-items: center;
  gap: 9px;
}
