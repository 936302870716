.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  padding: 64px 0 0;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  @media screen and (min-width: 1000px) {
    position: absolute;
    padding: 0 0 64px;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 31px 36px 50px;
  position: relative;
}

.formBackground {
  position: absolute;
  z-index: -1;
  background: var(--back-gradient);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    top: -153px;
    right: -94px;
    animation: animate 15s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    left: -84px;
    right: 0;
    bottom: -50px;
    animation: animate 30s linear infinite;
  }
}

@keyframes animate {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(100px);
  }
}

.body {
  margin: -20px 0 0;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  padding: 58px 37px 400px;
  display: flex;
  flex-direction: column;
  // gap: 60px;
}

.checkItem {
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 0px 0 25px;
}

.checkbox {
  width: 19px;
  height: 19px;
}

.checkText {
  color: #363942;
  font-size: 16px;
  font-family: Roboto;
}

.checkSelect {
  margin: 20px 0 5px;
}

.checkLabel {
  display: flex;
  align-items: center;
  gap: 7px;
}

.dateItem {
  margin-bottom: 43px;
}

.dateText {
  color: #bfc8e8;
  font-size: 16px;
  font-family: Roboto;
  margin: 0 0 0;
}

.datepickerWrapper {
  width: 100%;
}

.datepicker {
  border-bottom: 1px solid #bfc8e8;
  width: 100%;
  padding: 10px 0;
  // background-image: url(../../../assets/logo_calendar.svg);
  background-position: right 7px bottom 6px;
  background-repeat: no-repeat;
  background-size: 33px;
}

.addition {
  margin: 0px 0 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.buttons {
  position: fixed;
  right: 40px;
  bottom: 87px;
  display: flex;
  gap: 23px;

  @media screen and (min-width: 1000px) {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 60px;
  }
}

.button_reset {
  padding: 10px 28px;
  border-radius: 20px;
  color: var(--gray);
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 18.75px;
  border: 1px solid var(--gray);
  background-color: #fff;
}

.button_submit {
  padding: 10px 28px;
  background: var(--button-gradient);
  border-radius: 20px;
  color: #fff;
  font-size: 16px;
  font-family: Roboto;
  font-weight: 500;
  line-height: 18.75px;
}
