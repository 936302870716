.info {
  width: 100%;
  margin: 200px 0 0;
  background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1000px) {
    // display: none;
    min-height: 90vh;
    margin: 0;
  }
}

.heading {
  color: #bfc8e8;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 169px;
  text-align: center;
}

.subheading {
  color: #bfc8e8;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0 30px;
}