.component {
  position: relative;
}

.heading {
  padding: 0 23px;
  margin: 0 0 10px;
}

.userInfo {
  display: flex;
  align-items: center;
  gap: 11px;
}

.userAvatar {
  width: 29px;
  height: 29px;
  border-radius: 50%;
}

.userName {
  color: #363942;
  text-align: center;
  font-family: "Roboto", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%; /* 23px */
  letter-spacing: 0.23px;
}

.items {
  padding: 10px 23px 100px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  color: #363942;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
  letter-spacing: 0.5px;
}

.text {
  color: #363942;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.6;
  margin: 10px 0 0;
}
