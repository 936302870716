.column {
  display: flex;
  // width: 100%;
  // max-width: 435px;
  position: absolute;
  z-index: 5;
  top: 0;
  bottom: 0;

  @media (max-width: 1000px) {
    display: none;
  }

  @media (min-width: 1400px) {
    position: relative;
  }
}

.additional {
  width: 100%;
  display: flex;
  gap: 10px;
  padding: 0 0 0 40px;

  @media (max-width: 1000px) {
    flex-direction: column-reverse;
    padding: 0;
    height: 100%;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      height: 0;
      width: 0;
      padding: 0;
    }
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 134px);
  overflow-y: scroll;
  padding: 0 0;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  @media (max-width: 1000px) {
    overflow: visible;
    max-height: 100%;
    padding: 0 0 70px;
  }
}

.comments {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 10px 50px;
}

.chatContainer {
  position: sticky;
  bottom: 0px;
  left: 0;
  right: 0;
  padding: 0 10px;

  @media (max-width: 1000px) {
    position: fixed;
    bottom: 70px;
  }
}

.info {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
}

.body {
  background-color: #ffffff;
  padding: 35px 36px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  // min-height: 80vh;
  position: relative;
}

.buttons {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  background-color: #ffffff;
  border-top: 1px solid var(--gray);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  animation: show 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  z-index: 11;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY((100px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loading {
  position: fixed;
  right: 40px;
  bottom: 87px;
  z-index: 2;
}
