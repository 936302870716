.buttons {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 70px;
  background-color: #fff;
  border-top: 1px solid var(--gray);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 50px;
  animation: show 500ms cubic-bezier(0.4, 0, 0.2, 1) forwards;
  z-index: 11;
}

@keyframes show {
  from {
    opacity: 0;
    transform: translateY((100px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
