.column {
  display: flex;
  width: 100%;
  max-width: 435px;
}

.additional {
  width: 100%;
  max-width: 435px;

  @media (max-width: 1000px) {
    display: none;
  }
}

.newTask {
  position: fixed;
  bottom: 100px;
  right: 20px;
  border-radius: 60px;
  background: var(--back-gradient);
  width: 60px;
  height: 60px;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    opacity: 0.8;
  }

  &::after {
    content: "+";
    display: flex;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 1000px) {
    display: none;
  }
}
