.info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  position: relative;
  // overflow-y: auto;
  min-width: auto;
  width: 100%;
  flex-shrink: 1;
  background: #fff;

  overflow-y: scroll;
  height: 100%;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  &_blocked {
    overflow-y: hidden;
  }

  @media screen and (min-width: 420px) {
    min-width: 400px;
  }

  @media screen and (min-width: 1000px) {
    width: auto;
    max-height: calc(100vh - 134px);
    // на мобилке отрезается часть контента без верхнего паддинга - закомментировал - переставил в десктопную версию, поскольку иначе прокручивалась вся страница
    &::-webkit-scrollbar {
      // height: 0;
      // width: 0;
      // padding: 0;
      width: 12px;
      background-color: #f2f5ff;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // border-radius: 10px;
      background-color: #f2f5ff;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #8391a5;
      min-height: 50px;
    }
  }
}

.heading {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 26px 23px 0;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 400px) {
    padding: 26px 5px 0;
  }
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.text {
  color: #363942;
  font-size: 13px;
  font-family: Roboto;
  line-height: 19px;
}

.tagContainer {
  padding: 35px 30px 0;
  width: 100%;
  max-width: 100%;
  position: relative;

  @media (max-width: 400px) {
    padding: 35px 5px 0;
  }

  &:before {
    content: "";
    position: absolute;
    // left: 0px;
    right: 30px;
    top: 0;
    bottom: 0px;
    // height: 100%;
    width: 10px;
    background: linear-gradient(90deg, rgba(139, 167, 32, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;

    @media (max-width: 400px) {
      right: 5px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 30px;
    // right: 0px;
    top: 0;
    bottom: 0px;
    // height: 100%;
    width: 10px;
    background: linear-gradient(270deg, rgba(139, 167, 32, 0) 0%, rgba(255, 255, 255, 1) 100%);
    pointer-events: none;

    @media (max-width: 400px) {
      left: 5px;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  min-height: 40px;
  width: 100%;
  // max-width: calc(100% - 60px);
}

.tag {
  min-width: 100px;
  // width: 100vw;
  border-radius: 75px;
  background: #e5eafc;
  box-shadow: 17px 26px 25px 0px rgba(226, 226, 226, 0.25);
  padding: 11px 7px;
  transition: all 0.3s ease;
  color: #363942;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 18px;
  text-wrap: nowrap;
  -webkit-tap-highlight-color: transparent;

  &_active {
    background: #3f52b6;
    color: #fff;
  }
}

.columns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 1px;
  margin: 23px 0 0;
  padding: 0 0 1px;
  border-bottom: 1px solid #d9d9d9;
}

.column {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 4px solid #bfc8e8;
  padding: 0 0 4px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 23px 100px;
  gap: 24px;
  margin: 43px 0 0;
  overflow-y: scroll;
}

.appContainer {
  width: 40px;
  height: 100%;
  position: relative;
  background: #fff;
  border-right: 1px solid black;

  @media screen and (min-width: 1400px) {
    width: auto;
  }
}

.extendButton {
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 12px;
  border-left: 1px solid #d9d9d9;
  border-right: transparent;

  &_active {
    left: 415px;
    border-left: transparent;
    border-right: 1px solid #d9d9d9;
  }

  @media screen and (min-width: 1400px) {
    display: none;
  }
}

.applications {
  position: absolute;
  z-index: 5;
  overscroll-behavior: contain;
  overflow-y: auto;
  max-height: calc(100vh - 134px);
  height: 100%;
  background-color: transparent;
  transition: all 0.3s ease;
  width: 0;
  background-color: #ffffff;
  border-right: 1px solid #d9d9d9;

  &::-webkit-scrollbar {
    height: 0;
    width: 0;
    padding: 0;
  }

  &_open {
    width: 435px;
  }

  @media screen and (min-width: 1400px) {
    position: relative;
    width: auto;
  }
}

.preloader {
  width: 100%;
  max-width: 100px;
  margin: 0 0 0 auto;
  position: sticky;
  right: 0;
  bottom: 100px;
  z-index: 1;
}
