.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @media screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 216px 1fr;
    padding: 0;
  }
}

.info {
  margin: 200px 0 0;
  background-color: #fff;
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  color: #bfc8e8;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 40px;
  line-height: 169px;
  text-align: center;
}

.subheading {
  color: #bfc8e8;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  margin: 0 30px;
}
