.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  height: 64px;
  padding: 0 20px;
  width: 100%;
  // background-color: #7d7d7e;
  background-color: #f6f8fa;
  border-bottom: 1px solid #d0d7de;

  @media screen and (min-width: 1000px) {
    position: relative;
    min-height: 70px;
    // padding: 0 20px 0 43px;
  }
}

.logo {
  width: 100%;
  max-width: 150px;
  height: 100%;
  max-height: 52px;
}

.attr_desk {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 29px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
}

.attr_mobile {
  display: block;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;

  @media screen and (min-width: 1000px) {
    display: none;
  }
}

.notification {
  display: block;
  width: 41px;
  height: 41px;
  background: url(./assets/notification.svg) center no-repeat;
  transition: all 0.3s ease;
  margin-left: auto;

  &_active {
    background: url(./assets/notification_outline.svg) center no-repeat;
  }

  @media screen and (min-width: 1000px) {
    margin-left: 0;
  }
}

.heading {
  display: flex;
  gap: 21px;
  align-items: center;
}
.arrow {
  width: 34px;
  height: 34px;
  background: url(./assets/arrow.svg) center no-repeat;
  cursor: pointer;
  margin-right: 20px;
}
.title {
  color: #fff;
  font-size: 20px;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: 700;
  line-height: 24px;
  max-width: 300px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subtitle {
  color: #fff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
}

.text {
  // color: #fff;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 15.733px;
  color: black;
}

.profile {
  width: 42px;
  height: 42px;
  background: url(./assets/desktop/profile.svg) center no-repeat;
  display: block;
}

.exit {
  width: 42px;
  height: 42px;
  background: url(./assets/desktop/exit.svg) center no-repeat;
  display: block;
  cursor: pointer;
}

.newTask {
  width: 34px;
  height: 34px;
  // background: url(../../assets/header/newtask.svg) center no-repeat;
  display: block;
  cursor: pointer;
}

.edit {
  padding: 0 10px;
  width: 26px;
  height: 24px;
  // background: url(../../assets/header/header_edit.svg) center no-repeat;
  display: block;
  cursor: pointer;
}

.attachment {
  display: block;
  width: 30px;
  height: 30px;
  // background: url(../../assets/header/attachment_white.svg) center no-repeat;
  position: relative;
}
