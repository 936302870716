.page {
  width: 100%;
  height: 100vh;
  display: flex;
}

.content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.children {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 1000px) {
    padding: 64px 0 0;
  }
}
