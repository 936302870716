.heading {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 110px 36px 60px;
  position: relative;
  width: 100%;

  @media screen and (min-width: 1000px) {
    padding: 35px 36px 400px;
    position: inherit;
    min-height: 100%;
  }
}

.formBackground {
  position: absolute;
  z-index: -1;
  background: var(--back-gradient);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &::before {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    top: -153px;
    right: -94px;
    animation: animate 15s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: 0;
    width: 300px;
    height: 360px;
    border-radius: 100%;
    opacity: 0.15;
    background: #2e3a59;
    left: -84px;
    right: 0;
    bottom: -50px;
    animation: animate 30s linear infinite;
  }
}

@keyframes animate {
  0% {
    transform: translateY(100px);
  }
  50% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(100px);
  }
}

.label {
  position: relative;
  border: none;
  border-bottom: 1px solid rgba(215, 221, 240, 0.5);
  display: flex;
  justify-content: space-between;
}

.address {
  border: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;

  &__text {
    font-family: Roboto;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    padding: 0;
    text-transform: capitalize;
    color: #bfc8e8;
  }
}

.checkItem {
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 0;
}

.checkbox {
  width: 19px;
  height: 19px;
}

.checkText {
  color: #fff;
  font-family: Roboto;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; /* 13px */
  letter-spacing: 0.13px;
}

.selectContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.body {
  margin: -23px 0 0;
  background-color: #ffffff;
  border-radius: 20px 20px 0 0;
  padding: 46px 37px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 80vh;
  position: relative;
  // gap: 60px;

  @media screen and (min-width: 1000px) {
    display: none;
  }
}
