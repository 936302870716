.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.title {
  color: #363942;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.link {
  width: 109px;
  height: 32px;
  background: #fbfcfe;
  stroke-width: 0.5px;
  stroke: #dde4f0;
  filter: drop-shadow(0px 2px 1px rgba(64, 72, 82, 0.05));
  color: #5a6acf;
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.5px;
}

.image {
  width: 100%;
  max-width: 400px;
}
